<template>
  <div class="User_manage">
    <div class="index_main" ref="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条数据吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <div class="index_right">
        <p class="title" style="font-size: 14px">
          {{ companyName }}
        </p>
        <p class="search">
          <el-input
            type="text"
            size="mini"
            v-model="searchForm.name"
            placeholder=" 请输入用户名/账号/手机号"
          ></el-input>
          <button
            class="btn1"
            @click="
              currentPage2 = 1;
              getAllUserName();
            "
          >
            <i class="el-icon-search"></i>
            查询
          </button>
          <!-- <button
            class="btn2"
            @click="
              modal = 1;
              modalState = 0;
            "
          >
            <i class="el-icon-plus"></i>
            新增
          </button> -->
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column prop="nickName" label="预警类型"> </el-table-column>
            <el-table-column prop="userName" label="预警对象"> </el-table-column>
            <el-table-column prop="mobile" label="预警内容"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-edit"
                      v-if="permissoin.includes('edit')"
                      :command="{ num: 2, data: scope.row }"
                      >编辑</el-dropdown-item
                    >
                    <!-- <el-dropdown-item
                      icon="el-icon-edit-outline"
                      :command="{ num: 1, data: scope.row }"
                      >更改权限</el-dropdown-item
                    > -->
                    <!-- <el-dropdown-item
                      icon="el-icon-crop" 
                      v-if="permissoin.includes('repwd')"
                      :command="{ num: 5, data: scope.row }"
                      >重置密码</el-dropdown-item> -->
                    <el-dropdown-item
                      icon="el-icon-delete"
                      v-if="permissoin.includes('del')"

                      :command="{ num: 4, data: scope.row }"
                      >删除</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            @size-change="handleSizeChange"
            :page-sizes="[5, 10, 15]"
            prev-text="上一页"
            next-text="下一页"
            layout="sizes,total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if="modalState == 0">新增用户</span>
          <span v-if="modalState == 1">编辑</span>
          <span
            @click="
              ruleForm = { pwd: 123456,userType:1,};
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <el-form
            :model="ruleForm"
            :rules="rules" status-icon
            ref="ruleForm"
            label-width="80px"
            label-position="left"
          >
            <el-form-item label="姓名:" prop="nickName">
              <el-input v-model="ruleForm.nickName" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="机构:" prop="orgId">
              <el-cascader
                :collapse-tags="true"
                ref="cas"
                size="mini"
                v-model="ruleForm.orgId"
                :props="props"
                :options="treedata"
                filterable
              >
              </el-cascader>
            </el-form-item>
            <!-- <el-form-item label="类型" prop="userType" v-if="modalState == 0">
                  <el-radio-group v-model="ruleForm.userType" size='mini'>
                    <el-radio :label="0">超级管理员</el-radio>
                    <el-radio :label="1">普通用户</el-radio>
                  </el-radio-group>
                </el-form-item> -->
            <el-form-item label="手机号:" prop="mobile">
              <el-input v-model="ruleForm.mobile" size="mini" ></el-input>
            </el-form-item>
            <el-form-item label="账号:" prop="username" v-if="modalState == 0">
              <el-input v-model="ruleForm.username" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="员工编号:" prop="empCode">
              <el-input v-model="ruleForm.empCode" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="密码:" prop="password" v-if="modalState == 0">
              <el-input
                v-model="ruleForm.password"
                size="mini"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
          <p class="text_item"><span>*</span>用户权限设置</p>
          <el-table
            :data="roletableData"
            ref="multipleTable"
            style="width: 96%"
          >
            <el-table-column type="selection" width="60"> </el-table-column>
            <el-table-column prop="name" label="角色名称"> </el-table-column>
            <el-table-column prop="roleCode" label="角色编码" width="180">
            </el-table-column>
          </el-table>
        </div>
        <div class="bottom">
          <button class="btn_red" @click="onsubmit">保存</button>
          <button
            class="btn_gray"
            @click="ruleForm = { pwd: 123456,userType:1, };modal = 0;">
            取消
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAll,userdel,
  authPhone,
  userGetAll,
  userGetCurry,
  userAdd,
  userEdit,
  roleGetList,
  getUserById,repwd
} from "@/api/apis.js";
import { timestampToTime } from "@/utils/getTime.js";
export default {
  data() {
    var checkIdmobile = (rule, value, cb) => {
      if (!/^1[3-9]\d{9}$/.test(value)) {
        return cb(new Error("您输入的电话号码不是有效格式"));
      }
      let data = { mobile: this.ruleForm.mobile };
      authPhone(data).then((res) => {
        if (res.code != 200) {
          this.checkFlag = false;
          return cb(new Error("该手机号已绑定"));
        } else {
          return cb();
        }
      });
    };
    return {
      options: [],
      form: { modalState: 0,state:0 },
      info: 1,
      roletableData: [],
      timestampToTime,
      modalState: 0,
      currentPage2: 1,
      transferValue: [],
      transferData: [],
      pageSize: 10,
      treeList:[],
      projectP:-1,
      companyName: "",
      drawTitle: "人居集团",
      hide: 1,
      modal: 0,
      total: 15,
      permissoin: ["get"],
      searchForm: {},
      treeData: [],
      treedata: [],
      currentId: "",
      ruleForm: {
        pwd: 123456,
        userType: 1,
      },
      dialogVisible:false,
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      transfer: false,
      checkFlag: true,
      defaultProps: {
        children: "children",
        label: "name",
      },
      tableData: [],
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        orgId: [{ required: true, message: "请选择机构", trigger: "blur" }],
        mobile: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { validator: checkIdmobile, trigger: "blur" },
        ],
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        nickName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getAllUserName();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getAllUserName();
    },
    // authPhoneHandle(){
    //   let data={mobile:this.ruleForm.mobile}
    //   authPhone(data).then(res=>{
    //      if (res.code != 200) {
    //     this.checkFlag = false;
    //      this.$message.error('该手机号已绑定')
    //   }
    //   })
    // },
    projectPChange(v,i){
      this.projectP=i
        this.currentId = v.id;
        this.companyName = v.name;
      this.getAllUserName();
    },
    search(){
      if(this.form.input2!=''){
        this.form.state=1
        let data={name:this.form.input2}
      getAll(data).then(res=>{
        if(res.code==200){
          this.treeList=res.data
        }
      })
      }else{
        this.form.state=0
        this.getdata()
      }     
    },
    deletePro(){
      let data ={id:this.form.id}
        userdel(data).then(res=>{
          if(res.code==200){
            this.$message({
              type:'success',
              message:res.msg
            })
            this.dialogVisible=false
            this.getAllUserName()
          }else{
           this.$message.error(res.data.msg)
          }
        })
    },
    handleCommand(d) {
      this.form.id = d.data.userId;
      if (d.num == 1) {
        this.transfer = true;
      } else if (d.num == 2) {
        let data = { id: d.data.userId };
        this.$refs.multipleTable.clearSelection();
        getUserById(data).then((res) => {
          if (res.code == 200) {
            this.roletableData.forEach((item) => {
              (res.data.roleIdList || []).forEach((item1) => {
                if (item1 == item.id) {
                  this.$refs.multipleTable.toggleRowSelection(item);
                }
              });
            });
            this.ruleForm = res.data;
            this.modal = 1;
            this.modalState = 1;
          }
        });
      }if(d.num==4){
        this.dialogVisible=true     
      }else if(d.num==5){
        let data={userId:d.data.userId}
        repwd(data).then(res=>{
          if(res.code==200){
            this.$message({
              type:'success',
              message:res.msg
            })
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    handleNodeClick(data) {
      this.companyName = data.name;
      this.currentId = data.id;
      this.currentPage2=1
      this.getAllUser();
    },
    getAllUserName() {
      let data = {
        orgId: this.currentId,
        username: this.searchForm.name,
        pageNum: this.currentPage2,
        pageSize: this.pageSize,
      };
      userGetCurry(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total-0;
        } else {
          this.tableData = [];
          this.total =0
        }
      });
    },
    onsubmit() {
      let roleArr = this.$refs.multipleTable.selection;
      let idArr = roleArr.map((item) => item.id);
      if (this.modalState == 0) {
        let data = {};
        data = {
          nickName: this.ruleForm.nickName,
          userType: 1,
          mobile: this.ruleForm.mobile,
          empCode:this.ruleForm.empCode,
          username: this.ruleForm.username,
          password: this.ruleForm.password,
          orgId:this.ruleForm.orgId
            ? this.ruleForm.orgId[this.ruleForm.orgId.length - 1]
            : "",
          roleIdList: idArr.length > 0 ? idArr.join(",") : "",
        };
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            userAdd(data).then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "新增成功",
                });
                this.getAllUser();
                this.modal = 0;
                this.ruleForm = {};
              } else {
                this.$message.error(res.data.msg);
              }
            });
          } else {
            return false;
          }
        });
      } else if (this.modalState == 1) {
        let data = {};
        data = {
          nickName: this.ruleForm.nickName,
          orgId: this.ruleForm.orgId instanceof Array?this.ruleForm.orgId[this.ruleForm.orgId.length-1]:this.ruleForm.orgId ,
          mobile: this.ruleForm.mobile,
          empCode:this.ruleForm.empCode,
          roleIdList: idArr.length > 0 ? idArr.join(",") : "",
          id: this.form.id,
        };
        userEdit(data).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.modal = 0;
            this.ruleForm = {};
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    //获取用户结合
    getAllUser() {
      let data = {
        orgId: this.currentId,
        pageNum: this.currentPage2,
        pageSize: this.pageSize,
      };
      userGetAll(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total-0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
  },
  mounted() {
  
    //获取权限
    this.permissoin=[]
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){  
            this.permissoin.push(item.permission)        
      }
    })
    this.getAllUser();
    roleGetList().then((res) => {
      if (res.code == 200) {
        this.roletableData = res.data.list;
      }
    });
      this.$refs.company_main.style.height =
      this.$refs.index_main.offsetHeight*0.8795 + "px";
  },
};
</script>

<style lang="less" >
.User_manage {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  aside {
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
      line-height: 40px;
      margin-top: 10px;
      width: 320px;
      box-sizing: border-box;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      img {
        margin-right: 6px;
      }
      .el-select {
        .el-input {
          width: 100px;
        }
        .el-input__inner {
          border: none;
        }
      }
    }
    .search {
      padding-left: 10px;
      .el-input-group__append {
        padding: 0 6px;
        background-color: #e7eaef;
        border: none;
        cursor: pointer;
      }
      .el-input {
        position: relative;
        top: 14px;
        width: 300px;
        .el-input__inner {
          background-color: #e7eaef;
          border: none;
        }
      }
    }
    .company {
      height: 780px;
      box-sizing: border-box;
      margin-top: 30px;
      .project_item {
          border-bottom: 1px dashed #ebf0f5;
          font-size: 12px;
          padding-left: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }
        .active {
          color: #466cd4;
        }
    }
  }
  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 28px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 268px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 456px;
      // min-height: 560px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        height: 450px;
        margin-bottom: 10px;
        padding-left: 34px;
        box-sizing: border-box;
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            width: 60px;
          }
        }
        .el-table {
          .cell {
            line-height: 30px;
          }
        }
        .el-form {
          .el-form-item__label {
            font-size: 12px;
            color: #222328;
          }
          .el-form-item {
            margin-bottom: 10px;
          }
          .el-input {
            width: 270px;
          }
          .el-form-item__error {
            padding-top: 0px;
          }
        }
      }
      .bottom {
        height: 60px;
        display: flex;
        justify-content: center;
        & > button:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>